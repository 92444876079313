import React from 'react';
import { Entity, BoxGraphics, CesiumContext, useCesium } from 'resium';
import { Cartesian3, Color, ImageMaterialProperty, ReferenceProperty } from 'cesium';

export const GroundStations = ({ groundStationList }) => {
  const dimensions = new Cartesian3(100000, 100000, 100000);
  const material = new ImageMaterialProperty({ image: '/images/aws-gs.png' });
  const { viewer } = useCesium();

  const getPosition = (region) => {
    return ReferenceProperty.fromString(viewer.entities, region + '#position');
  };

  return (
    <>
      {groundStationList.map((gs) => {
        return (
          <Entity id={gs.groundStationId} name={gs.groundStationName} position={getPosition(gs.region)} key={gs.groundStationId}>
            <BoxGraphics dimensions={dimensions} material={material} />
          </Entity>
        );
      })}
    </>
  );
};
