import React, { useEffect, useState, useRef } from 'react';
import { BillboardGraphics, Entity, PathGraphics, useCesium } from 'resium';
import { Color } from 'cesium';
export const Orbits = ({ satellites, setSatelliteMeta }) => {
  return (
    <>
      {satellites
        .filter((sat) => sat.entity !== null && sat.show)
        .map((sat, i) => {
          let { entity, meta } = sat;
          let { path, billboard } = entity;
          return (
            <Entity key={meta.noradSatelliteID} id={entity.id} name={entity.name} position={entity.position}>
              <PathGraphics leadTime={path.leadTime} trailTime={path.trailTime} width={3} material={Color.fromCssColorString(sat.color).withAlpha(0.75)} />
              <BillboardGraphics image={billboard.image} scale={billboard.scale} />
            </Entity>
          );
        })}
    </>
  );
};
