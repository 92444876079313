import React, { useEffect, useRef, Fragment } from 'react';
import { CzmlDataSource, Entity, PolylineGraphics, useCesium, Clock, PathGraphics } from 'resium';
import { ArcType, Color, PolylineGlowMaterialProperty, PositionPropertyArray, ReferenceProperty, TimeIntervalCollection, JulianDate } from 'cesium';

export const Contacts = ({ contactList, satellites, regionList, groundStationList }) => {
  let { viewer } = useCesium();

  let glow = new PolylineGlowMaterialProperty({ color: Color.ORANGE, glowPower: 1 });

  return (
    <>
      {contactList.map((contact, i) => {
        let satellite = satellites.find((sat) => contact.satelliteArn === sat.meta.satelliteArn);
        if (!satellite.entity) return null;
        let startTime = new JulianDate.fromIso8601(contact.startTime.replace(' ', 'T'));
        let endTime = new JulianDate.fromIso8601(contact.endTime.replace(' ', 'T'));
        let interval = new TimeIntervalCollection.fromJulianDateArray({ julianDates: [startTime, endTime] });
        let groundStation = groundStationList.find((gs) => contact.groundStation === gs.groundStationId);
        let satToGs = new PositionPropertyArray([satellite.entity.position, new ReferenceProperty(viewer.entities, groundStation.region, ['position'])]);
        return (
          <Fragment key={i}>
            <Entity name={'Contact ' + (i + 1) + ' Downlink Segment'} position={satellite.entity.position} show={satellite.show} availability={interval}>
              <PathGraphics
                material={new PolylineGlowMaterialProperty({ color: Color.fromCssColorString(satellite.color), glowPower: 1 })}
                width={3}
                leadTime={JulianDate.compare(endTime, startTime)}
              />
            </Entity>
            <Entity name={'Contact ' + (i + 1) + ' Downlink'} show={satellite.show} availability={interval}>
              <PolylineGraphics followSurface={false} positions={satToGs} material={glow} width={3} arcType={ArcType.NONE} />
            </Entity>
          </Fragment>
        );
      })}
    </>
  );
};
