import { CzmlDataSource, JulianDate, Cartesian3, ClockStep, viewerCesium3DTilesInspectorMixin, Event, Ion } from 'cesium';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Camera, Clock, Globe, useCesium, Viewer } from 'resium';
import { GroundStations } from './components/GroundStations';
import { Regions } from './components/Regions';
import { Orbits } from './components/Orbits';
import { Contacts } from './components/Contacts';
import { Menu } from './components/Menu';

function App() {
  let now = new Date();
  let tm = new Date();
  let ys = new Date();
  ys.setDate(now.getDate() - 1);
  tm.setDate(now.getDate() + 1);

  const [groundStationList, setGroundStationList] = useState([]);
  const [satellites, setSatellites] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [startTime, setStartTime] = useState(new JulianDate.fromDate(ys));
  const [stopTime, setStopTime] = useState(new JulianDate.fromDate(tm));
  const [currentTime, setCurrentTime] = useState(new JulianDate.now());
  const [shouldAnimate, setShouldAnimate] = useState(false);

  let viewer = useRef();

  const orbitDataUrl = 'https://wrqk3x0eo0.execute-api.us-east-2.amazonaws.com/orbits';

  const regionList = [
    { id: 'us-east-2', name: 'US East (Ohio)', position: Cartesian3.fromDegrees(-83, 39.96, 1) },
    { id: 'us-east-1', name: 'US East (N. Virginia)', position: Cartesian3.fromDegrees(-78.45, 38.13, 1) },
    { id: 'us-west-1', name: 'US West (N. California)', position: Cartesian3.fromDegrees(-121.96, 37.75, 1) },
    { id: 'us-west-2', name: 'US West (Oregon)', position: Cartesian3.fromDegrees(-123.88, 46.15, 1) },
    { id: 'af-south-1', name: 'Africa (Cape Town)', position: Cartesian3.fromDegrees(18.6, -33.75, 1) },
    { id: 'ap-east-1', name: 'Asia Pacific (Hong Kong)', position: Cartesian3.fromDegrees(113.99, 22.35, 1) },
    { id: 'ap-south-1', name: 'Asia Pacific (Mumbai)', position: Cartesian3.fromDegrees(72.74, 19.08, 1) },
    { id: 'ap-northeast-3', name: 'Asia Pacific (Osaka-Local)', position: Cartesian3.fromDegrees(135.14, 34.66, 1) },
    { id: 'ap-northeast-2', name: 'Asia Pacific (Seoul)', position: Cartesian3.fromDegrees(126.98, 37.56, 1) },
    { id: 'ap-southeast-1', name: 'Asia Pacific (Singapore)', position: Cartesian3.fromDegrees(103.8, 1.37, 1) },
    { id: 'ap-southeast-2', name: 'Asia Pacific (Sydney)', position: Cartesian3.fromDegrees(151.2, -33.86, 1) },
    { id: 'ap-northeast-1', name: 'Asia Pacific (Tokyo)', position: Cartesian3.fromDegrees(139.42, 35.41, 1) },
    { id: 'ca-central-1', name: 'Canada (Central)', position: Cartesian3.fromDegrees(-73.6, 45.5, 1) },
    { id: 'eu-central-1', name: 'Europe (Frankfurt)', position: Cartesian3.fromDegrees(8, 50, 1) },
    { id: 'eu-west-1', name: 'Europe (Ireland)', position: Cartesian3.fromDegrees(-8, 53, 1) },
    { id: 'eu-west-2', name: 'Europe (London)', position: Cartesian3.fromDegrees(-0.1, 51, 1) },
    { id: 'eu-south-1', name: 'Europe (Milan)', position: Cartesian3.fromDegrees(8.84, 45.4, 1) },
    { id: 'eu-west-3', name: 'Europe (Paris)', position: Cartesian3.fromDegrees(2.35, 48.86, 1) },
    { id: 'eu-north-1', name: 'Europe (Stockholm)', position: Cartesian3.fromDegrees(17.84, 59.32, 1) },
    { id: 'me-south-1', name: 'Middle East (Bahrain)', position: Cartesian3.fromDegrees(50.31, 25.94, 1) },
    { id: 'sa-east-1', name: 'South America (São Paulo)', position: Cartesian3.fromDegrees(-46.38, -23.34, 1) },
  ];

  const fetchJson = async (url, property) => {
    try {
      const res = await fetch(url);
      const data = await res.json();
      return data[property];
    } catch (error) {
      console.error(error);
    }
  };

  const ColorPicker = {
    colors: ['#00ff00', '#0000ff', '#ff00ff'],
    random: () => '#' + Math.floor(Math.random() * 16777215).toString(16),
    pick: function () {
      return this.colors.length ? this.colors.pop() : this.random();
    },
  };

  const onLoad = async () => {
    // Mock ListGroundStations API Call
    setGroundStationList(await fetchJson('/mocks/ListGroundStations.json', 'groundStationList'));
    // Mock ListSatellites API Call
    const gsSatellites = await fetchJson('/mocks/ListSatellites.json', 'satellites');
    const czml = await Promise.allSettled(gsSatellites.map((sat) => new CzmlDataSource().load(orbitDataUrl + '?noradId=' + sat.noradSatelliteID)));
    setSatellites(() => {
      return czml.map((c, i) => {
        let entity = null;
        if (c.status === 'fulfilled') {
          let { id, position, path, billboard } = c.value.entities.values[0];
          entity = {
            id: gsSatellites[i].noradSatelliteID,
            name: id.substr(12, Infinity),
            position,
            path,
            billboard,
          };
        }
        const meta = { ...gsSatellites[i] };
        return { entity, meta, show: !!entity && i === 1, color: ColorPicker.pick() };
      });
    });
    setContactList([
      // ListContacts API call would go here
      ...((await fetchJson('/contacts/Ohio%201/contacts_aqua_ohio.json', 'contactList')) || []),
      ...((await fetchJson('/contacts/Ohio%201/contacts_noaa_ohio.json', 'contactList')) || []),
      ...((await fetchJson('/contacts/Ohio%201/contacts_snpp_ohio.json', 'contactList')) || []),
      ...((await fetchJson('/contacts/Bahrain%201/contacts_aqua_bahrain.json', 'contactList')) || []),
      ...((await fetchJson('/contacts/Bahrain%201/contacts_noaa_bahrain.json', 'contactList')) || []),
      ...((await fetchJson('/contacts/Bahrain%201/contacts_snpp_bahrain.json', 'contactList')) || []),
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    onLoad();
    viewer.current.cesiumElement.timeline.updateFromClock();
    viewer.current.cesiumElement.timeline.zoomTo(startTime, stopTime);
  }, []);

  return (
    <Viewer ref={viewer} full>
      <Menu
        contactList={contactList}
        satellites={satellites}
        setSatellites={setSatellites}
        setStartTime={setStartTime}
        setStopTime={setStopTime}
        setCurrentTime={setCurrentTime}
        setShouldAnimate={setShouldAnimate}
        isLoading={isLoading}
      />
      <Globe showGroundAtmosphere={true} enableLighting={true} dynamicAtmosphereLighting={true} />
      <Clock startTime={startTime} stopTime={stopTime} currentTime={currentTime} multiplier={20} shouldAnimate={shouldAnimate} />
      <Regions regionList={regionList} />
      <GroundStations groundStationList={groundStationList} />
      <Orbits satellites={satellites} />
      <Contacts contactList={contactList} regionList={regionList} groundStationList={groundStationList} satellites={satellites} />
    </Viewer>
  );
}

export default App;
