import React from 'react';
import { useCesium } from 'resium';
import './MenuSatellites.css';

export const MenuSatellites = ({ satellites, setSatellites }) => {
  const handleChangeShow = (satelliteArn) => {
    setSatellites((satellites) => {
      console.log(satellites);
      return satellites.map((sat) => {
        return sat.meta.satelliteArn === satelliteArn ? { ...sat, show: !sat.show } : sat;
      });
    });
  };

  const handleChangeColor = (e, satelliteArn) => {
    setSatellites(
      satellites.map((sat) => {
        return sat.meta.satelliteArn === satelliteArn ? { ...sat, color: e.target.value } : sat;
      })
    );
  };

  return (
    <div className="satellite-list">
      <div className="satellite-list-header">
        <div>Norad ID</div>
        <div>Name</div>
        <div>Show</div>
        <div>Color</div>
      </div>
      {satellites.map((sat) => {
        return (
          <div key={sat.meta.satelliteArn} className="satellite-list-item">
            <div>{sat.meta.noradSatelliteID}</div>
            <div>{sat.entity ? sat.entity.name : ''}</div>
            <div>
              <input type="checkbox" checked={sat.show} onChange={() => handleChangeShow(sat.meta.satelliteArn)} />
            </div>
            <div>
              <input type="color" value={sat.color} onChange={(e) => handleChangeColor(e, sat.meta.satelliteArn)} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
