import { CzmlDataSource } from 'cesium';
import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import './MenuTLE.css';

export const MenuTLE = ({ satellites, setSatellites }) => {
  const url = 'https://wwaxh3thgf.execute-api.us-east-2.amazonaws.com/DEV';

  const [formData, setFormData] = useState({
    noradId: '',
    name: '', //'ISS (ZARYA)',
    tle1: '', //'1 25544U 98067A   20031.24963938  .00001307  00000-0  31791-4 0  9996',
    tle2: '', //'2 25544  51.6454 310.9655 0005435 211.0342 248.3831 15.49121879210634',
  });

  const handleSubmit = async () => {
    const { noradId, name, tle1, tle2 } = formData;
    if (noradId) {
      if (satellites.find((sat) => sat.meta.noradSatelliteID == noradId)) return;
      try {
        const data = await fetch(url + '/noradidinput' + '?noradID=' + noradId).then((res) => res.json());
        const ds = await new CzmlDataSource.load(JSON.parse(data));
        const { id, position, path, billboard } = ds.entities.values[0];
        setSatellites((satellites) => {
          return [
            ...satellites,
            {
              color: '#' + Math.floor(Math.random() * 16777215).toString(16),
              show: true,
              meta: {
                noradSatelliteID: noradId,
                satelliteArn: 'userInput:' + noradId,
              },
              entity: {
                id: noradId,
                name: id.substr(12, Infinity),
                position,
                path,
                billboard,
              },
            },
          ];
        });
      } catch (error) {
        console.error(error);
      }
    }
    if (tle1 && tle2 && name) {
      try {
        const data = await fetch(
          url + '/tleinput' + `?satelliteName=${encodeURIComponent(name)}&tle1=${encodeURIComponent(tle1)}&tle2=${encodeURIComponent(tle2)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        ).then((res) => res.json());
        const ds = await new CzmlDataSource.load(await JSON.parse(data));
        const { id, position, path, billboard } = ds.entities.values[0];
        setSatellites((satellites) => {
          return [
            ...satellites,
            {
              color: '#' + Math.floor(Math.random() * 16777215).toString(16),
              show: true,
              meta: {
                noradSatelliteID: tle1.substr(2, 5),
                satelliteArn: 'userInput:' + id,
              },
              entity: {
                id: tle1.substr(3, 8),
                name: name,
                position,
                path,
                billboard,
              },
            },
          ];
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="form-wrapper">
      <div className="input-wrapper">
        <span>Enter NORAD ID</span>
      </div>
      <div className="form-row">
        <div className="input-wrapper">
          <input
            placeholder="Norad ID"
            value={formData.noradId}
            disabled={formData.name + formData.tle1 + formData.tle2 !== ''}
            onChange={(e) => setFormData({ ...formData, noradId: e.target.value })}
          />
        </div>
      </div>
      <div className="input-wrapper">
        <span>Or enter TLE</span>
      </div>
      <div className="form-row">
        <div className="input-wrapper">
          <input
            placeholder="Satellite Name"
            value={formData.name}
            disabled={formData.noradId !== ''}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </div>
      </div>
      <div className="input-wrapper">
        <input
          placeholder="TLE Line 1"
          value={formData.tle1}
          disabled={formData.noradId !== ''}
          onChange={(e) => setFormData({ ...formData, tle1: e.target.value })}
        />
      </div>
      <div className="input-wrapper">
        <input
          placeholder="TLE Line 2"
          value={formData.tle2}
          disabled={formData.noradId !== ''}
          onChange={(e) => setFormData({ ...formData, tle2: e.target.value })}
        />
      </div>
      <div className="input-wrapper">
        <div className="btn" onClick={handleSubmit}>
          <div>
            <FaDownload />
            <span style={{ paddingLeft: '1rem' }}>Get Orbit Data</span>
          </div>
        </div>
      </div>
    </div>
  );
};
