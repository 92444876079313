import React, { useState } from 'react';
import './Menu.css';
import { FaMinusSquare, FaPlusSquare, FaSatelliteDish, FaSatellite, FaDownload, FaPlus, FaPlusCircle } from 'react-icons/fa';
import { MenuContacts } from './MenuContacts';
import { MenuSatellites } from './MenuSatellites';
import {MenuTLE } from './MenuTLE'

export const Menu = ({ contactList, satellites, setSatellites, setStartTime, setStopTime, setCurrentTime, setShouldAnimate, isLoading }) => {
  const [tab, setTab] = useState('CONTACTS');
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="menu-container">
      <div className="header">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ backgroundColor: '#fff', height: '1.75rem', width: '1.75rem', padding: '1px', borderRadius: '5px' }}>
            <img style={{ maxHeight: '100%', maxWidth: '100%' }} src="/images/orbital-vision.png"></img>
          </div>
          <h2 style={{ paddingLeft: '1rem' }}>Orbital Vision</h2>
        </div>
        {isLoading ? (
          <div className="spinner" />
        ) : (
          <div className="toggle" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <FaMinusSquare size="1.5rem" /> : <FaPlusSquare size="1.5rem" />}
          </div>
        )}
      </div>
      <div className={'menu-content ' + (isOpen ? '' : 'closed')}>
        <div className="tabs">
          <div className={'tab-item ' + (tab === 'CONTACTS' ? 'selected' : '')} onClick={() => setTab('CONTACTS')}>
            <FaSatelliteDish />
            <span>Contacts</span>
          </div>
          <div className={'tab-item ' + (tab === 'SATELLITES' ? 'selected' : '')} onClick={() => setTab('SATELLITES')}>
            <FaSatellite />
            <span>Satellites</span>
          </div>
          <div className={'tab-item ' + (tab === 'TLE' ? 'selected' : '')} onClick={() => setTab('TLE')}>
            <FaPlusCircle/>
            <span>Add Orbit</span>
          </div>
        </div>
        <div className="menu-body">
          {
            {
              CONTACTS: (
                <MenuContacts
                  contactList={contactList}
                  satellites={satellites}
                  setSatellites={setSatellites}
                  setStartTime={setStartTime}
                  setStopTime={setStopTime}
                  setCurrentTime={setCurrentTime}
                  setShouldAnimate={setShouldAnimate}
                />
              ),
              SATELLITES: <MenuSatellites satellites={satellites} setSatellites={setSatellites} />,
              TLE: <MenuTLE satellites={satellites} setSatellites={setSatellites}/>
            }[tab]
          }
        </div>
        <div />
      </div>
    </div>
  );
};
