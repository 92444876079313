import React, { useEffect } from 'react';
import { Cartesian3, ImageMaterialProperty } from 'cesium';
import { EllipseGraphics, Entity, EntityDescription, useCesium } from 'resium';

export const Regions = ({ regionList }) => {
  const material = new ImageMaterialProperty({ image: '/images/aws.png' });

  return (
    <>
      {regionList.map((region) => {
        return (
          <Entity {...region} key={region.id}>
            <EllipseGraphics semiMajorAxis={200000} semiMinorAxis={200000} material={material} />
          </Entity>
        );
      })}
    </>
  );
};
