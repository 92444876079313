import { Cartesian3, Cartographic, EllipseGraphics, JulianDate, ReferenceProperty } from 'cesium';
import React from 'react';
import { useCesium } from 'resium';
import './MenuContacts.css';

export const MenuContacts = ({ contactList, satellites, setSatellites, setStartTime, setStopTime, setCurrentTime, setShouldAnimate }) => {
  let { viewer } = useCesium();

  const handleOnClick = (startTime, stopTime, satelliteArn, groundStation) => {
    let gsCartesian = viewer.entities.getById(groundStation).position.getValue(startTime).clone();
    let gsCartographic = new Cartographic.fromCartesian(gsCartesian);
    viewer.camera.flyTo({
      destination: new Cartesian3.fromDegrees((gsCartographic.longitude / Math.PI) * 180, (gsCartographic.latitude / Math.PI) * 180, Math.pow(2, 24)),
    });
    setStartTime(new JulianDate.fromDate(startTime));
    setStopTime(new JulianDate.fromDate(stopTime));
    setCurrentTime(new JulianDate.fromDate(startTime));
    setShouldAnimate(true);
  };

  return (
    <div className="contact-list">
      <div className="contact-list-header">
        <div>Satellite</div>
        <div>Status</div>
        <div>Start (UTC)</div>
        <div>Duration</div>
        <div>Max Ele.</div>
        <div>GroundStation</div>
      </div>
      {contactList
        .sort((a, b) => (a.startTime > b.startTime ? 1 : -1))
        .map((contact, i) => {
          let { contactStatus, startTime, endTime, groundStation, region, maximumElevation } = contact;
          startTime = new Date(startTime);
          endTime = new Date(endTime);
          const [sat] = satellites.filter((sat) => sat.meta.satelliteArn === contact.satelliteArn);
          if (!sat.show) return null;
          const seconds = Math.round(endTime - startTime) / 1000;
          const minutes = Math.floor(seconds / 60);
          return (
            <div key={i} className="contact-list-item" onClick={() => handleOnClick(startTime, endTime, sat.meta.satelliteArn, groundStation)}>
              <div>{sat.entity ? sat.entity.name : sat.meta.noradSatelliteID}</div>
              <div>{contactStatus}</div>
              <div>{startTime.toUTCString().substring(0, 25)}</div>
              <div>{minutes + 'm ' + (seconds % (minutes * 60)) + 's'}</div>
              <div>{maximumElevation.value + '°'}</div>
              <div>{groundStation}</div>
            </div>
          );
        })}
    </div>
  );
};
